<template>
  <div>
    <div>
      <Divider dashed style="color:#fff">配置小组成员</Divider>
      <div>
        <Table
          stripe
          :data="tableData"
          :columns="tableColumns"
        ></Table>
        <div class="m-t-10">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="changePage"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserPage } from '@/api/os/user'
import { addWorkGroupUser } from '@/api/os/workgroupuser'
export default {
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 15,
        username: '',
        name: '',
        email: '',
        mobile: '',
        enabled: null,
        // publisherId: this.$store.getters.token.userInfo.publisherId
        companyId: this.$store.getters.token.userInfo.companyId
      },
      total: 0,
      tableData: [],
      tableColumns: [
        // { title: '编号', key: 'id', align: 'center' },
        { title: '用户名', key: 'username', align: 'center' },
        { title: '姓名', key: 'name', align: 'center' },
        { title: '手机号码', key: 'mobile', align: 'center' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.hanldAddUser(params.row)
                  }
                }
              }, '添加')
            ])
          }
        }
      ]
    }
  },
  computed: {
    workGroupId () {
      return this.$store.state.workGroup.workGroupId
    },
    workGroupUserList () {
      return this.$store.state.workGroup.workGroupUserData.users
    }
  },
  created () {
    this.getTableData()
  },
  methods: {
    getTableData () {
      getUserPage(this.query).then(res => {
        this.total = res.totalRow
        this.tableData = res.list
        this.query.pageNumber = res.pageNumber
      })
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    hanldAddUser (obj) {
      // 判断当前是否已经穿在该人员
      if (this.workGroupUserList.some(x => x.id === obj.id)) {
        this.$Notice.warning({ desc: '当前小组已存在该人员' })
        return false
      }
      const postData = {
        workgroupId: this.workGroupId,
        userId: obj.id
      }
      addWorkGroupUser(postData).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '添加小组成员成功' })
          this.$store.dispatch('getWorkGroupUserData')
        }
      })
    }
  }
}
</script>
